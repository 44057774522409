// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-erstkontakt-js": () => import("./../../../src/pages/erstkontakt.js" /* webpackChunkName: "component---src-pages-erstkontakt-js" */),
  "component---src-pages-fragen-js": () => import("./../../../src/pages/fragen.js" /* webpackChunkName: "component---src-pages-fragen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-psychotherapie-js": () => import("./../../../src/pages/psychotherapie.js" /* webpackChunkName: "component---src-pages-psychotherapie-js" */),
  "component---src-pages-qualitaetsstandards-js": () => import("./../../../src/pages/qualitaetsstandards.js" /* webpackChunkName: "component---src-pages-qualitaetsstandards-js" */),
  "component---src-pages-wartezeiten-js": () => import("./../../../src/pages/wartezeiten.js" /* webpackChunkName: "component---src-pages-wartezeiten-js" */),
  "component---src-pages-zu-meiner-person-js": () => import("./../../../src/pages/zu-meiner-person.js" /* webpackChunkName: "component---src-pages-zu-meiner-person-js" */)
}

