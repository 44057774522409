module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Praxis für Psychotherapie Telgte","short_name":"PsychotherapieTelgte","start_url":"/","background_color":"#d1913c","theme_color":"#d1913c","display":"minimal-ui","icon":"src/assets/images/angelika-behrenberg-cropped.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da8c86441bf5290535d0ab712b35d266"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
